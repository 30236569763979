.main-content-my {
  margin-left: 250px;
}

.sidebar-icon-color {
  color: #2b1a64;
  font-size: 14px;
  margin-bottom: 0;
}

.sidebar-icon-color:hover {
  color: #43c915;
  font-size: 14px;
  margin-bottom: 0;
}

.sidebar-icon-color-active {
  color: #43c915 !important;
  font-size: 14px;
  margin-bottom: 0;
}

.magin-left-sidebar {
  padding-left: 4%;
  position: absolute;
}

.hr-sidebar {
  margin-top: -50px;
  border-width: 2px;
  border-color: #2b1a64;
  width: 133%;
  margin-left: -44px;
}


.wallet_balance{
  cursor:pointer;
    color:green;
    background-color: white;

}
.wallet_balance:hover{

  color:black;
  /* background-color: green; */
}
/*mobile view*/
@media (max-width: 767px) {
  .main-content-my {
    margin-left: 0 !important;
  }

  .mobile-hide {
    display: none;
  }
}
