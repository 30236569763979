.loan-color {
    color: #b4b4dc;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
    margin-left: 16%;
}

.loan-bckg{
    background-color: #f7f7fb ;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
}

.loan-status-pendding {
    background-color: #b4b4dc;
    border-radius: 20px;
    font-size: 14px;
    padding: 4px;
    font-weight: 600;
}

.loan-status-due {
    background-color: #de8e86;
    border-radius: 20px;
    font-size: 14px;
    padding: 4px;
    font-weight: 600;
}

.loan-status-repaid {
    background-color: #81d481;
    border-radius: 20px;
    font-size: 14px;
    padding: 4px;
    font-weight: 600;
}

.loan-status-width{
    width: 12%;
}

.bg-gradient-loans {
    background: linear-gradient(87deg, #a5c953 0, #6fc90b 100%) !important;
}