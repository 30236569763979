.loan-img-color {
  color: #2b1a64;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 0.8rem;
  margin-left: 16%;
}
.loan-img-bckg {
  background-color: #ddddf3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 3px;
}

.loan-pendding-status {
  background-color: #2b1a64;
  border-radius: 20px;
  font-size: 14px;
  padding: 4px;
  font-weight: 600;
}

.loan-due-status {
  background-color: #ec1904;
  border-radius: 20px;
  font-size: 14px;
  padding: 4px;
  font-weight: 600;
}

.loan-repaid-status {
  background-color: #03e003;
  border-radius: 20px;
  font-size: 14px;
  padding: 4px;
  font-weight: 600;
}

.loan-status-width {
  width: 12%;
}

.bg-gradient-loans {
  background: linear-gradient(87deg, #a5c953 0, #6fc90b 100%) !important;
}

.dash-td {
  font-size: 16px !important;
  padding: 15px !important;
  text-align: center;
}
.text-bold {
  font-weight: bolder !important;
}
