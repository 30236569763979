.bg-readonly{
    background-color: white!important;
}

.margin-proname{
    margin-top: 15%;
}

.navigation{
    padding: 10px;
    width: 150px;
    text-align: center;
}

/*mobile view*/
@media (max-width:767px) {

    .profile-click-m{
        width: 100%;
    }

    .margin-proname{
        margin-top: 0!important;
    }

    .promargin-m{
        margin-top: -5rem !important;
    }
}
