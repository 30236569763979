.text-size{
    font-size: 175%;
}

.margin-overview-card-text{
    margin-top: 15px;
}

.style-click{
    cursor: pointer;
}

.bckgrd-color{
    background-color: #FBFAFF;
}

.card-style{
    border: none!important;
    box-shadow: rgba(0,0,0,0.04) 0px 2px 4px 0px, rgba(0, 0, 0, 0.08) 0px 1px 2px 0px!important;
    border-radius: 6px!important;
    padding: 5%;
}

.dash-trans{
    margin-bottom: -13px;
    font-size: 90%;
}

.dash-hr{
    margin-bottom: 0;
    margin-top: 0;
}

.dash-td{
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.margin-dash-text{
    margin-bottom: 2px;
    margin-top: 11px;
}

.width-icon-td{
    width: 1%!important;
}

.phone-bckg{
    background-color: #ddddf3;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
}

.phone-color{
    color: #2b1a64;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
    margin-left: 22%;
}

.tv-color{
    color: #ec1904;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
    margin-left: 15%;
}

.tv-bckg{
    background-color: #f7d5d2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
}

.wallet-color{
    color: #03e003;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 0.8rem;
    margin-left: 22%;
}

.wallet-bckg{
    background-color: #dbf3db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
}


.trans-debit-color {
    color: #ff0018;
}

.trans-credit-color {
    color: #1cdc48;
}







/*mobile view*/
@media (max-width:767px) {

    .trans-debit-color {
        padding-top: 0!important;
    }

    .trans-credit-color {
        padding-top: 0!important;
    }

}