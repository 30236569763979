.btn-ticket {
  width: 8%;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.itm-title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btm-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
}

/*mobile view*/
@media (max-width: 767px) {
  .btn-ticket {
    width: 30%;
  }
}
